import './App.css';
import Timer from "./coming_soon/countdown/Timer";
import Preloader from "./coming_soon/preloader/Preloader";
import Logo from "./assets/Logo_Color.jpg";

function App() {
    return (
        <div className="App">
            <div className="container" style={{display: 'flex', flexDirection: 'column', alignItems: 'center',
                justifyContent: 'center', minHeight: '100vh', maxHeight: '100vh', width: '100vw'}}>
                <div style={{display: 'flex', alignItems: 'center', marginBottom: 20}}>
                    <img src={Logo} width={140}/>
                </div>
                <h2 style={{color: '#2c9c5d', width: '85%', marginBottom: 0, marginTop: 40}}>
                    COMING SOON
                </h2>
                <p style={{fontsize: '12px', fontFamily: 'sans-serif', width: '85%', color: '#9E9E9E', marginBottom: 20}}>
                    To bring you a better web experience.
                </p>
                <Timer />
                <Preloader />
            </div>
        </div>
    );
}

export default App;
